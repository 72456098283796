<style lang="scss" scoped>
.my-collapse {
  border: 1px solid #ebeef5;
  margin-bottom: 40px;
  padding-left: 20px;
}
.my-collapse-title {
  i {
    font-size: 16px;
    margin-right: 10px;
    color: #999;
  }
  .el-collapse-item {
    border-left: solid 1px #ebeef5;
    border-right: solid 1px #ebeef5;
    padding: 0 20px;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
  }
}
</style>
<template>
  <el-collapse class="my-collapse sizing">
    <el-collapse-item name="1">
      <template #title>
        <div class="my-collapse-title">
          <i class="el-icon-info"></i>條件篩選
        </div>
      </template>
      <el-form
        size="small"
        :inline="true"
        :ref="(el) => (state.formInline = el)"
        label-width="100px"
        :model="state.form"
        class="demo-form-inline"
      >
        <el-form-item label="排序類型" prop="seatch_name">
          <el-select placeholder="請選擇" v-model="state.form.seatch_name">
            <el-option label="銷售額" value="1"></el-option>
            <el-option label="銷量" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-select placeholder="請選擇" v-model="state.form.sort">
            <el-option label="高到底" value="1"></el-option>
            <el-option label="低到高" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="創建時間" prop="time">
          <el-date-picker
            v-model="state.form.time"
            type="daterange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="screen-btnaction">
          <el-button type="primary" @click="seach">查詢</el-button>
          <el-button @click="resetValue">重置</el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";

export default {
  setup(props, { emit }) {
    const state = reactive({
      formInline: "",
      form: {
        seatch_name: "0",
        sort: "0",
        time: "",
      },
    });

    const resetValue = () => {
      // 重置
      state.form = {
        seatch_name: "0",
        sort: "0",
        time: "",
      };
    };

    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http } = proxy;

    const seach = () => {
      emit("seach", state.form);
    };

    return {
      state,
      resetValue,
      seach,
    };
  },
};
</script>
